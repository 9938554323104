<template>
    <div class="header flex-static">
        <header class="navbar">
            <section class="navbar-section">
                <base-link
                    :href="{ name: 'home' }"
                    @click.native="$emit('navigate', false)"
                >
                    <app-logo />
                </base-link>
            </section>
            <section class="navbar-section">
                <default-button
                    v-if="!!app_context && !!app_context.settings && app_context.settings.bug_reporter_active && (!!profile_id || isPublicPage)"
                    flavor="link"
                    class="mr-4"
                    @click.prevent="bug_report"
                >
                    <open-icon glyph="bug" />
                </default-button>
                <div
                    v-if="!!profile_id"
                    ref="main_menu"
                    :class="{ active: navigating }"
                    class="dropdown dropdown-right"
                >
                    <div class="btn-group">
                        <base-link
                            :href="{ name: 'profile' }"
                            :title="$root.translate('My Profile Page')"
                            class="btn btn-link"
                        >
                            <figure class="avatar avatar-sm">
                                <open-icon v-if="!profile_id || (!!profile && !profile.avatar)" glyph="user" size="2x" />
                                <img v-else :src="profile.avatar" :alt="profile.name">
                            </figure>
                        </base-link>
                        <base-link
                            href="#"
                            class="btn btn-link dropdown-toggle"
                            tabindex="0"
                            :title="$root.translate('Toggle Menu')"
                            @click.prevent="start_navigating"
                        >
                            <open-icon glyph="caret-down" />
                        </base-link>
                        <ul class="menu text-right">
                            <router-link
                                v-if="!!profile_id"
                                :to="{ name: 'profile' }"
                                tag="li"
                                active-class="active"
                                class="menu-item"
                                @click.native="$emit('navigate', false)"
                            >
                                <base-link
                                    :href="{ name: 'profile' }"
                                    :title="$root.translate('My Profile Page')"
                                >
                                    <small>{{ $root.translate('Logged in as') }}:</small><br/>
                                    {{ profile.first_name }}
                                </base-link>
                            </router-link>
                            <li class="divider"></li>
                            <router-link
                                :to="{ name: 'home' }"
                                tag="li"
                                active-class="active"
                                :exact="true"
                                class="menu-item"
                                @click.native="$emit('navigate', false)"
                            >
                                <base-link
                                    :href="{ name: 'home' }"
                                    :exact="true"
                                    :title="$root.translate('Dashboard')"
                                >
                                    {{ $root.translate("Dashboard") }}
                                </base-link>
                            </router-link>
                            <router-link
                                v-if="is_at_least_administrator"
                                :to="{ name: 'user.list' }"
                                tag="li"
                                active-class="active"
                                class="menu-item"
                                @click.native="$emit('navigate', false)"
                            >
                                <base-link
                                    :href="{ name: 'user.list' }"
                                    :title="$root.translate('Users')"
                                >
                                    {{ $root.translate("Users") }}
                                </base-link>
                            </router-link>
                            <router-link
                                v-if="is_at_least_administrator"
                                :to="{ name: 'settings' }"
                                tag="li"
                                active-class="active"
                                class="menu-item"
                                @click.native="$emit('navigate', false)"
                            >
                                <base-link
                                    :href="{ name: 'settings' }"
                                    :title="$root.translate('Settings')"
                                >
                                    {{ $root.translate("Settings") }}
                                </base-link>
                            </router-link>
                            <li v-if="is_at_least_administrator" class="divider"></li>
                            <li class="menu-item">
                                <base-link
                                    href="#"
                                    :title="$root.translate('Logout')"
                                    @click.prevent="logout"
                                >
                                    {{ $root.translate("Logout") }}
                                </base-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </section>
        </header>
    </div>
</template>

<script>
import { profile_utilities } from "@/nibnut/mixins"

import { AppLogo, DefaultButton, BaseLink, OpenIcon } from "@/nibnut/components"

export default {
    mixins: [profile_utilities],
    components: {
        AppLogo,
        DefaultButton,
        BaseLink,
        OpenIcon
    },
    watch: {
        $route: "stop_navigating"
    },
    methods: {
        bug_report () {
            this.$emit("bug-report")
        },
        scan () {
            this.$store.dispatch("SCAN", true)
        },
        login () {
            this.$store.dispatch("REQUEST_LOGIN", { panel_id: true })
        },
        start_navigating () {
            document.body.addEventListener("click", this.maybe_stop_navigating)
            this.navigating = true
        },
        stop_navigating () {
            this.navigating = false
            document.body.removeEventListener("click", this.maybe_stop_navigating)
        },
        maybe_stop_navigating (event) {
            if(event.target && !this.$refs.main_menu.contains(event.target)) {
                this.stop_navigating()
            }
        },
        logout () {
            this.stop_navigating()
            this.$store.dispatch("LOGOUT")
        }
    },
    props: {
        isPublicPage: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            navigating: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.header {
    position: sticky;
    flex: 0 0 100%;
    top: 0;
    padding: $layout-spacing-lg;
    background-color: $brand-color;
    z-index: $zindex-2;

    img.app-logo {
        height: 1.8rem;
    }
    .avatar {
        margin-top: -0.4rem;
    }
    .btn.btn-link {
        background: transparent;
        border: 0;
        color: $light-color;
        &:focus,
        &:active,
        &.active {
            color: $primary-color;
        }
        @include hover-supported {
            color: $primary-color;
        }
        i {
            font-size: 1rem;
        }
    }
}
</style>
