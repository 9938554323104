<template>
    <div>
        <data-table
            id="users-searches"
            :columns="visible_columns"
            :rows="filtered_rows"
            :search="state.search"
            :total="state.total"
            :found="state.found"
            :current-page="state.page"
            :total-pages="nb_pages"
            :can-add="false"
            @sort="sort_by"
            @search="search"
            @page="goto_page"
            @click="apply"
        >
            <template
                v-slot:summary="{ row }"
            >
                <h6>{{ row.content.name }}</h6>
            </template>
            <template
                v-slot:tbody="{ row, field }"
            >
                <div v-if="field === 'name'">
                    {{ row.content.name }}
                </div>
                <div v-if="field === '_buttons'" class="text-right">
                    <default-button
                        flavor="link"
                        small="sm"
                        :title="translate('Delete')"
                        @click.stop.prevent="confirm_record_delete(row)"
                    >
                        <open-icon glyph="backspace" />
                    </default-button>
                    <default-button
                        flavor="link"
                        small="sm"
                        :title="translate('Duplicate')"
                        @click.stop.prevent="duplicate(row)"
                    >
                        <open-icon glyph="copy" />
                    </default-button>
                    <default-button
                        flavor="link"
                        small="sm"
                        :title="translate('Rename')"
                        @click.stop.prevent="rename(row)"
                    >
                        <open-icon glyph="edit" />
                    </default-button>
                    <!--
                    <default-button
                        flavor="link"
                        small="sm"
                        :title="translate('Export')"
                        @click.stop.prevent
                    >
                        <open-icon glyph="file-csv" />
                    </default-button>
                    //-->
                </div>
                <span v-else>{{ row[field] }}</span>
            </template>
        </data-table>

        <prompt
            v-if="prompting"
            v-bind="prompt_props"
            @cancel="done_prompting"
            @save="prompted"
        />
        <confirmation
            v-if="!!confirming"
            v-bind="confirmation_props"
            @cancel="done_confirming"
            @confirmed="confirmed"
        />
    </div>
</template>

<script>
import { is_remote_data_table_source, confirms } from "@/nibnut/mixins"
import { handles_saved_searches } from "@/custom/mixins"

import { DefaultButton, OpenIcon } from "@/nibnut/components"

export default {
    name: "UserSearches",
    mixins: [is_remote_data_table_source, confirms, handles_saved_searches],
    components: {
        DefaultButton,
        OpenIcon
    },
    methods: {
        apply (row) {
            this.$emit("load", row)
        },
        confirm_record_delete (saved_search) {
            this.confirm(
                {
                    type: "warning",
                    title: this.$root.translate("Delete {name}", { name: saved_search.content.name }),
                    message: this.$root.translate("Do you really want to delete this saved search? There is no undo..."),
                    cancel: this.$root.translate("Keep"),
                    ok: this.$root.translate("Delete"),
                    saved_search
                },
                "delete-record"
            )
        },
        confirmed () {
            this.$store.dispatch(
                "RECORD_DELETE",
                {
                    entity: this.entity,
                    id: this.confirmation_props.saved_search.id
                }
            ).then(() => {
                this.done_confirming()
                this.refresh()
            }).catch(this.receive_error)
        },
        duplicate (saved_search) {
            this.prompt(
                {
                    title: this.$root.translate("Duplicate Search {name}", { name: saved_search.content.name }),
                    message: this.$root.translate("Enter a name for the new saved search:"),
                    value: `${saved_search.content.name} copy`,
                    ok: this.$root.translate("Save"),
                    saved_search
                },
                "duplicate"
            )
        },
        rename (saved_search) {
            this.prompt(
                {
                    title: this.$root.translate("Rename Search {name}", { name: saved_search.content.name }),
                    message: this.$root.translate("Enter a new name for your saved search:"),
                    ok: this.$root.translate("Save"),
                    saved_search
                },
                "rename"
            )
        },
        prompted (name) {
            if(this.prompting === "duplicate") {
                this.create_current_search(name, this.prompt_props.saved_search.content.criteria).then(() => {
                    this.refresh().then(() => {
                        if(this.saved_search_id) this.apply(this.saved_current_search)
                    })
                })
            } else if(this.prompting === "rename") {
                const data = {
                    content: {
                        name,
                        criteria: this.prompt_props.saved_search.content.criteria
                    }
                }
                return this.$store.dispatch("RECORD_SAVE", {
                    entity: "user_preference",
                    id: this.prompt_props.saved_search.id,
                    data
                }).then(record => {
                    if(record) this.saved_search_id = record.id
                    this.done_prompting()
                }).catch(error => this.$error(error.message))
            } else this.done_confirming()
        }
    },
    computed: {
        state_identifier () {
            return "user-searches"
        },
        fields () {
            return ["fieldset::default"]
        },
        can_refresh () {
            return this.profile_id && (this.userId !== null)
        }
    },
    props: {
        userId: {
            type: Number,
            default: 0
        }
    },
    data () {
        return {
            entity: "user_preference",
            columns: {
                name: { label: "Name", sort: "asc", type: "alpha" },
                _buttons: { label: " ", sort: false }
            },
            default_state: {
                per_page: 10,
                page: 1,
                sort_by: "name",
                sort_dir: "asc",
                filter_on: null,
                filter: null,
                archived: false,
                search: "",
                total: 0,
                found: 0,
                type: "searches"
            }
        }
    }
}
</script>
