import { mapGetters } from "vuex"

import prompts from "@/nibnut/mixins/Prompts"

const handles_saved_searches = {
    mixins: [prompts],
    methods: {
        save_current_search (criteria) {
            // ask for name ; on "OK", save search
            if(this.saved_search_id) {
                const data = {
                    content: {
                        name: this.saved_current_search.content.name,
                        criteria
                    }
                }
                return this.$store.dispatch("RECORD_SAVE", {
                    entity: "user_preference",
                    id: this.saved_search_id,
                    data
                }).catch(error => this.$error(error.message))
            } else {
                this.prompt(
                    {
                        title: this.$root.translate("Save New Search"),
                        message: this.$root.translate("Please give your saved search a name, for easy identification."),
                        ok: this.$root.translate("Save"),
                        criteria
                    }
                )
            }
        },
        create_current_search (name, criteria = null) {
            return this.$store.dispatch("CREATE_RECORD", {
                entity: "user_preference",
                data: { content: { name, criteria: criteria || this.prompt_props.criteria } }
            }).then(record => {
                if(record) this.saved_search_id = record.id
                this.done_prompting()
            }).catch(error => this.$error(error.message))
        },
        clear_current_search () {
            this.saved_search_id = 0
        }
    },
    computed: {
        ...mapGetters(["entity_record"]),
        saved_current_search () {
            return this.entity_record("user_preference", this.saved_search_id) || {}
        }
    },
    data () {
        return {
            saved_search_id: 0
        }
    }
}

export default handles_saved_searches
