<template>
    <div
        :class="{ active }"
        class="variations-display"
    >
        <div class="main">
            <span v-if="!variations.length" class="text-gray">({{ translate("None") }})</span>
            <div v-else>
                <div>
                    <slot name="main" :variation="current_variation">
                        <strong>{{ current_variation.name }}</strong>
                        <form-toggle-input
                            v-if="active"
                            :id="`validation-${current_variation.id}`"
                            name="validated_at"
                            type="checkbox"
                            :value="!!current_variation.validated_at"
                            size="sm"
                            :required="false"
                            @input="$emit('validate', current_variation)"
                        >
                            {{ translate("Validated") }}
                            <template v-slot:hint v-if="!!current_variation.verifier_name">
                                {{ current_variation.verifier_name }}
                            </template>
                        </form-toggle-input>
                    </slot>
                </div>
                <div>
                    <default-button
                        v-if="variations.length > 1"
                        flavor="link"
                        size="sm"
                        @click.prevent="active = !active"
                    >
                        <span v-if="active">{{ translate("Hide") }}</span>
                        <span v-else>{{ translate("+{n} more", { n: (variations.length - 1) }) }}</span>
                    </default-button>
                </div>
            </div>
        </div>
        <div class="variations">
            <table class="table table-striped table-hover">
                <tbody>
                    <tr
                        v-for="variation in addl_variations"
                        :key="variation.id"
                    >
                        <td class="nibnut-hoverable">
                            <div class="d-flex align-items-center">
                                <div class="flex-variable">
                                    <slot :variation="variation"></slot>
                                    <form-toggle-input
                                        :id="`validation-${variation.id}`"
                                        name="validated_at"
                                        type="checkbox"
                                        :value="!!variation.validated_at"
                                        size="sm"
                                        :required="false"
                                        @input="$emit('validate', variation)"
                                    >
                                        {{ translate("Validated") }}
                                        <template v-slot:hint v-if="!!variation.verifier_name">
                                            {{ variation.verifier_name }}
                                        </template>
                                    </form-toggle-input>
                                </div>
                                <div class="flex-static nibnut-hover-opaque">
                                    <default-button
                                        flavor="link"
                                        color="secondary"
                                        size="sm"
                                        :title="translate('Make it the main one')"
                                        @click.prevent.stop="$emit('toggle', variation)"
                                    >
                                        <open-icon glyph="arrow-circle-left" size="2x" />
                                    </default-button>
                                </div>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import FormToggleInput from "@/nibnut/components/Inputs/FormToggleInput"
import DefaultButton from "@/nibnut/components/Buttons/DefaultButton"
import OpenIcon from "@/nibnut/components/OpenIcon"

export default {
    name: "VariationsDisplay",
    components: {
        FormToggleInput,
        DefaultButton,
        OpenIcon
    },
    computed: {
        current_variation () {
            if(!this.currentVariationId) return this.variations[0]
            return this.variations.find(variation => variation.id === this.currentVariationId)
        },
        addl_variations () {
            if(!this.currentVariationId) return this.variations.slice(1)
            return this.variations.filter(variation => variation.id !== this.currentVariationId)
        }
    },
    props: {
        currentVariationId: {
            type: Number,
            default: 0
        },
        variations: {
            type: Array,
            default () {
                return []
            }
        }
    },
    data () {
        return {
            active: false
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.variations-display {
    display: flex;
    flex-direction: column;
    border: $border-width solid transparent;
    border-radius: $border-radius;
    width: 100%;
    transition: all 0.3s ease-in-out;

    & > .main {
        flex: 1;
        height: auto;
        padding: 0;
        transition: all 0.3s ease-in-out;

        & > div {
            display: flex;

            & > div:first-child {
                flex: 1 1 auto;
            }
            & > div:last-child {
                flex: 0 0 auto;
                & > .btn {
                    margin-left: $unit-2;
                }
            }
            @media (max-width: $size-sm) {
                display: block;

                & > div:last-child {
                    & > .btn {
                        margin-left: 0;
                    }
                }
            }
        }
    }
    & > .variations {
        flex: 0;
        overflow: hidden;
        height: 0;
        max-height: 0;
        width: auto;
        padding: 0;
        transition: all 0.3s ease-in-out;

        table.table td {
            border: 0;

            .btn.btn-sm {
                height: auto;
                line-height: 1.3em;
            }
        }

        &.btn-link {
            background: transparent;
            border-color: transparent;
            color: $secondary-color;
            &:focus,
            &:active,
            &.active {
                color: $secondary-color-dark;
            }
            @include hover-supported {
                color: $secondary-color-dark;
            }
        }
        .btn.btn-link {
            text-align: left;

            &,
            &:focus,
            &:active,
            &.active {
                background: transparent;
                border-color: transparent;
            }
            @include hover-supported {
                background: transparent;
                border-color: transparent;
            }
        }
    }

    &.active {
        border-color: $border-color;
        & > .main {
            padding: $layout-spacing;
        }
        & > .variations {
            flex: 2;
            height: auto;
            max-height: none;
            padding: $layout-spacing;
        }
    }
}
</style>
