
<template>
    <div class="footer flex-static">
        <footer class="text-center">
            &copy;&nbsp;{{ current_year }}&nbsp;{{ setting("customer_name") }}<br />
            {{  $root.translate("App sprouted from a") }}&nbsp;<a href="https://nibnut.com"><img src="@/assets/img/nibnut.png" alt="nibnut" /></a>&nbsp;&bull;&nbsp;v.{{ app_version }}
        </footer>
    </div>
</template>

<script>
import { string_utilities, misc_utilities } from "@/nibnut/mixins"

export default {
    name: "AppFooter",
    mixins: [string_utilities, misc_utilities]
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.footer {
    margin-top: $layout-spacing-lg;
}
</style>
