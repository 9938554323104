<template>
    <div class="nibnut-data-grid">
        <table class="table table-scroll">
            <tbody>
                <tr
                    v-for="(column, field) in columns"
                    :key="field"
                >
                    <th>
                        <slot
                            name="thead"
                            :column="column"
                            :field="field"
                        >
                            {{ column.label }}
                        </slot>
                    </th>
                    <td
                        v-for="row in rows"
                        :key="row[rowIdField]"
                    >
                        <slot
                            name="tbody"
                            :row="row"
                            :field="field"
                        >
                            {{ row[field] }}
                        </slot>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
export default {
    name: "DataGrid",
    props: {
        columns: {
            type: Object,
            required: true
        },
        rows: {
            type: Array,
            required: true
        },
        rowIdField: {
            type: String,
            default: "id"
        }
    }
}
</script>

<style lang="scss">
@import "@/assets/sass/variables";

.nibnut-data-grid {
    tbody {
        tr {
            th {
                position: sticky;
                left: 0;
                white-space: nowrap;
                background-color: $bg-color;
                width: 0;
                border-bottom-width: $border-width;
                z-index: $zindex-1;
            }
            &:last-child {
                th, td { border-bottom: 0; }
            }
        }
    }
    tbody {
        tr {
            td.loader-container {
                padding: $control-padding-y $control-padding-x;
                text-align: center;

                & > .loader {
                    margin: 0 auto;
                }
            }
            td.cell-buttons {
                width: 0;
            }
            td + td {
                border-left: $border-width solid $border-color;
            }
        }
    }

    &.non-sticky {
        tbody {
            th {
                position: static;
                left: auto;
            }
        }
    }
}
@media print {
    .nibnut-data-grid {
        font-size: 0.8em;

        tbody {
            th {
                white-space: normal;
            }
        }
    }
}
</style>
